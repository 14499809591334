<template>
  <div id="holoverz-collection-screen">
    <div class="top-img-container">
        <div class="shadow">
            <img src="/images/screens/collections/desktop/holox_top_img_shadow.png" class="d-none d-lg-block" style="width: 100%;"/>
            <img src="/images/screens/collections/mobile/holox_top_img_shadow.png" class="d-lg-none d-xs-block d-sm-block" style="width: 100%;"/>
        </div>
        <div class="text-section text-center">
            <div class="title headline-l">
                {{ $t('holoverzCollectionsScreen.title') }}
            </div>
            <div class="sub mt-3">
                {{ $t('holoverzCollectionsScreen.description_1') }}
            </div>
        </div>
        <div class="button-container">
            <base-button class="button" :text="$t('holoverzCollectionsScreen.goToHoloxButton')" @clicked="goToHoloxBoxes()" />
        </div>
        <img src="/images/screens/collections/desktop/holox_top.png" class="d-none d-lg-block" style="width: 100%;"/>
        <img src="/images/screens/collections/mobile/holox_top.png" class="mobile-img d-lg-none d-xs-block d-sm-block"/>
    </div>
    <div class="advantages-container">
        <div v-for="(advantage, i) in advantages" :key="i" class="advantage py-5">
            <img class="img" :src="advantage.img"/>
            <div class="title"> {{ $t(advantage.title) }} </div>
        </div>
    </div>
    <div id="holox-boxes" class="boxes-container">
        <div class="title"> {{ $t('holoverzCollectionsScreen.holoxBoxes.title') }} </div>
        <!-- <div class="boxes" :style="!needCarousel ? 'display: flex' : 'display: none'"> -->
        <div class="boxes d-none d-md-flex">
            <div v-for="(box, i) in holoxBoxes" :key="i" class="holox-box py-5">
                <img class="img" :src="box.img"/>
                <div class="title">{{ $t(box.title) }}</div>
                <div class="description" v-html="$t(box.description)"></div>
                <div class="price"> {{ $t(box.price) }} </div>
                <div class="button-container">
                    <base-button class="button" :text="$t('holoverzCollectionsScreen.holoxBoxes.boxes.buyNowButton')" @clicked="goToHowToBuy()" />
                </div>
            </div>
        </div>
        <!-- <div :style="needCarousel ? 'display: block' : 'display: none'"> -->
        <div class="d-md-none d-xs-block d-sm-block">
            <carousel
                class="boxes"
                ref="carousel"
                :loop="true"
                :autoplay="false"
                :margin="75"
                :nav="true"
                :navText="navTextCarousel"
                :dots="true"
                :items="holoxBoxes.length"
                :rewind="false"
                :autoplayHoverPause="false"
                :slideTransition="'linear'"
                :touchDrag="false "
                :responsive="carouselConfig"
            >
                <div v-for="(box, i) in holoxBoxes" :key="i" class="holox-box py-5">
                    <img class="img" :src="box.img"/>
                    <div class="title">{{ $t(box.title) }}</div>
                    <div class="description" v-html="$t(box.description)"></div>
                    <div class="price"> {{ $t(box.price) }} </div>
                    <div class="button-container">
                        <base-button class="button" :text="$t('holoverzCollectionsScreen.holoxBoxes.boxes.buyNowButton')" @clicked="goToHowToBuy()" />
                    </div>
                </div>
            </carousel>
        </div>
    </div>
    <div id="info-section" class="holox-info-container">
        <div class="title">{{ $t('holoverzCollectionsScreen.holoxInfos.title') }}</div>
        <div class="infos-container">
            <div v-for="(info, i) in holoxInfos" :key="i" class="info">
                <div class="title-section">
                    <img class="img" :src="info.img">
                    <div class="box-title">{{ $t(info.title) }}</div>
                </div>
                <div class="description" v-html="$t(info.description)"></div>
            </div>
        </div>
        <div class="footnote" v-html="$t('holoverzCollectionsScreen.holoxInfos.footnote')"></div>
        <div class="button-container">
            <base-button class="button" :text="$t('holoverzCollectionsScreen.holoxInfos.buyNowButton')" @clicked="goToWallet()" />
        </div>
    </div>
    <div class="more-cards-section d-lg-none d-xs-block d-sm-block">
        <div class="title">
            {{ $t('holoverzCollectionsScreen.otherProducts') }}
        </div>
    </div>
    <div class="img-container ">
        <img src="/images/screens/collections/desktop/hero.png" class="d-none d-md-block"/>
        <img src="/images/screens/collections/mobile/hero.png" class="d-md-none d-xs-block d-sm-block"/>
        <div class="blur">
            <img src="/images/screens/collections/desktop/others-blur.png" class="d-none d-lg-block" style="width: 100%;"/>
        </div>
        <div class="other-products-title d-none d-lg-block"> {{ $t('holoverzCollectionsScreen.otherProducts') }} </div>
    </div>
    <!-- <div class="headline-container text-center">
        <div class="title headline-l">
            {{ $t('holoverzCollectionsScreen.description_2') }}
        </div>
    </div>
    <div class="steps-section">
        <div class="container pt-5 pb-5">
            <div class="text-center headline-l mb-5">
                {{ $t('holoverzCollectionsScreen.steps.title') }}
            </div>
            <div class="steps-container">
                <div class="stepbox">
                    <div class="step-title mb-3">
                        <img src="/images/screens/collections/step_1.svg">
                        {{ $t('holoverzCollectionsScreen.steps.1.title') }}
                    </div>
                    <div class="step-description">
                        {{ $t('holoverzCollectionsScreen.steps.1.description') }}
                        <span><a href="mailto:support@blockben.com" class="underline">support@blockben.com</a></span>
                        {{ $t('holoverzCollectionsScreen.steps.1.description_2') }}
                    </div>

                </div>
                <div class="stepbox">
                    <div class="step-title mb-3">
                        <img src="/images/screens/collections/step_2.svg">
                        {{ $t('holoverzCollectionsScreen.steps.2.title') }}
                    </div>
                    <div class="step-description">
                        {{ $t('holoverzCollectionsScreen.steps.2.description') }}
                    </div>
                    <div class="step-description highlight">
                        {{ $t('holoverzCollectionsScreen.steps.2.description_2') }}
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="firstCard card py-5">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 col-12 justify-content-center d-none d-md-flex">
                    <img :src="firstCard.image"/>
                </div>
                <div class="col-md-6 col-12">
                    <div class="title headline-l">
                        {{ $t('holoverzCollectionsScreen.cards.1.title') }}
                    </div>
                    <div class="text-bold mt-2">
                        {{ firstCard.number }}
                    </div>
                    <div class="col-md-6 col-12 justify-content-center py-3 d-md-none d-xs-flex d-sm-flex card-img-container">
                        <img :src="firstCard.image"/>
                    </div>
                    <div class="text-bold mt-1">
                        {{ $t('holoverzCollectionsScreen.cards.numberOfPieces') }} {{ firstCard.quantity }}
                    </div>
                    <div class="paragraph mt-3">
                        {{ $t('holoverzCollectionsScreen.cards.1.description') }}
                    </div>
                    <div class="title price price-primary mt-3">
                        {{ firstCard.price }}
                    </div>
                    <div class="paragraph mt-1">
                        {{ $t('holoverzCollectionsScreen.cards.or') }}
                    </div>
                    <div class="title price price-primary mt-1">
                        {{ firstCard.price_2 }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-for="(card, i) in cards" :key="i" class="card product-light py-5">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 col-12 d-none d-md-flex justify-content-center">
                    <img :src="card.image"/>
                </div>
                <div class="col-md-6 col-12">
                    <div class="title headline-l">
                        {{ $t(card.title) }}
                    </div>
                    <div class="text-bold mt-2">
                        {{ card.number === 0 ? $t('holoverzCollectionsScreen.cards.noSerialNumber') : card.number}}
                    </div>
                    <div class="col-md-6 col-12 d-md-none d-xs-flex d-sm-flex justify-content-center py-3 card-img-container">
                        <img :src="card.image" class="img"/>
                    </div>
                    <div class="text-bold mt-1">
                        {{ $t('holoverzCollectionsScreen.cards.numberOfPieces') }} {{ card.quantity }}
                    </div>
                    <div class="paragraph mt-3">
                        {{ $t(card.description) }}
                    </div>
                    <div class="title price price-primary mt-3">
                        {{ card.price }}
                    </div>
                    <div v-if="card.price_2" class="paragraph mt-1">
                        {{ $t('holoverzCollectionsScreen.cards.or') }}
                    </div>
                    <div class="title price price-primary mt-1">
                        {{ card.price_2 }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container pt-3 pb-3 priceInfo">
        <div class="paragraph">
            {{ $t('holoverzCollectionsScreen.priceInfo') }}
        </div>
        <div class="paragraph mt-2">
            {{ $t('holoverzCollectionsScreen.priceInfo_2') }}
        </div>
        <div class="paragraph mt-2">
            {{ $t('holoverzCollectionsScreen.priceInfo_3') }}
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import carousel from 'vue-owl-carousel';

export default {
  name: 'HoloverzCollectionScreen',
  components: {
    carousel
  },
  data: () => ({
    firstCard: {
        image: "/images/screens/collections/desktop/soldoutcard_desktop.png",
        title: "holoverzCollectionsScreen.cards.1.title",
        number: "01",
        quantity: "1",
        description: "holoverzCollectionsScreen.cards.1.description",
        price: "3.317 EUR*",
        price_2: "284 Holoverz token + 99 EUR**"
      },
    cards: [
      {
        image: "/images/screens/collections/desktop/blue_card.png",
        title: "holoverzCollectionsScreen.cards.2.title",
        number: "02-05",
        quantity: "4",
        description: "holoverzCollectionsScreen.cards.2.description",
        price: "2.596 EUR*",
        price_2: "222 Holoverz token + 99 EUR**"
      },
      {
        image: "/images/screens/collections/desktop/red_card.png",
        title: "holoverzCollectionsScreen.cards.3.title",
        number: "06-10",
        quantity: "5",
        description: "holoverzCollectionsScreen.cards.3.description",
        price: "1.731 EUR*",
        price_2: "148 Holoverz token + 99 EUR**"
      },
      {
        image: "/images/screens/collections/desktop/green_card.png",
        title: "holoverzCollectionsScreen.cards.4.title",
        number: "11-25",
        quantity: "15",
        description: "holoverzCollectionsScreen.cards.4.description",
        price: "1.298 EUR*",
        price_2: "111 Holoverz token + 99 EUR**"
      },
      {
        image: "/images/screens/collections/desktop/violet_card.png",
        title: "holoverzCollectionsScreen.cards.5.title",
        number: "26-50",
        quantity: "25",
        description: "holoverzCollectionsScreen.cards.5.description",
        price: "865 EUR*",
        price_2: "74 Holoverz token + 99 EUR**"
      },
      {
        image: "/images/screens/collections/desktop/grey_card.png",
        title: "holoverzCollectionsScreen.cards.6.title",
        number: 0,
        quantity: "200",
        description: "holoverzCollectionsScreen.cards.6.description",
        price: "433 EUR - SOLD OUT"
      },
    ],
    advantages: [
        {
            title: 'holoverzCollectionsScreen.advantages.inimitable',
            img: '/images/screens/collections/inimitable.svg'
        },
        {
            title: 'holoverzCollectionsScreen.advantages.unremakeable',
            img: '/images/screens/collections/unremakeable.svg'
        },
        {
            title: 'holoverzCollectionsScreen.advantages.unique',
            img: '/images/screens/collections/totally_unique.svg'
        }
    ],
    holoxBoxes: [
        {
            img: '/images/screens/collections/holox_blue.png',
            title: 'holoverzCollectionsScreen.holoxBoxes.boxes.blue.title',
            description: 'holoverzCollectionsScreen.holoxBoxes.boxes.blue.description',
            price: 'holoverzCollectionsScreen.holoxBoxes.boxes.blue.price'
        },
        {
            img: '/images/screens/collections/holox_gray.png',
            title: 'holoverzCollectionsScreen.holoxBoxes.boxes.gray.title',
            description: 'holoverzCollectionsScreen.holoxBoxes.boxes.gray.description',
            price: 'holoverzCollectionsScreen.holoxBoxes.boxes.gray.price'
        },
        {
            img: '/images/screens/collections/holox_yellow.png',
            title: 'holoverzCollectionsScreen.holoxBoxes.boxes.yellow.title',
            description: 'holoverzCollectionsScreen.holoxBoxes.boxes.yellow.description',
            price: 'holoverzCollectionsScreen.holoxBoxes.boxes.yellow.price'
        },
        {
            img: '/images/screens/collections/holox_silver.png',
            title: 'holoverzCollectionsScreen.holoxBoxes.boxes.silver.title',
            description: 'holoverzCollectionsScreen.holoxBoxes.boxes.silver.description',
            price: 'holoverzCollectionsScreen.holoxBoxes.boxes.silver.price'
        },
        {
            img: '/images/screens/collections/holox_black.png',
            title: 'holoverzCollectionsScreen.holoxBoxes.boxes.black.title',
            description: 'holoverzCollectionsScreen.holoxBoxes.boxes.black.description',
            price: 'holoverzCollectionsScreen.holoxBoxes.boxes.black.price'
        }
    ],
    carouselConfig: {
      0: {
        items: 1,
      },
      992: {
        items: 1
      },
      1400: {
        items: 1
      },
    },
    navTextCarousel: ['<img src="/images/screens/collections/arrow-left.svg"/>', '<img src="/images/screens/collections/arrow-right.svg"/>'],
    holoxInfos: [
        {
            img: '/images/screens/collections/info1.svg',
            title: 'holoverzCollectionsScreen.holoxInfos.infos.first.title',
            description: 'holoverzCollectionsScreen.holoxInfos.infos.first.description',
        },
        {
            img: '/images/screens/collections/info2.svg',
            title: 'holoverzCollectionsScreen.holoxInfos.infos.second.title',
            description: 'holoverzCollectionsScreen.holoxInfos.infos.second.description',
        },
        {
            img: '/images/screens/collections/info3.svg',
            title: 'holoverzCollectionsScreen.holoxInfos.infos.third.title',
            description: 'holoverzCollectionsScreen.holoxInfos.infos.third.description',
        }
    ]
  }),
  methods: {
    goToHoloxBoxes() {
        const holoxBoxes = document.getElementById('holox-boxes');
        window.scrollTo({
            top: holoxBoxes.offsetTop - 80,
            behavior: "smooth"
        });
    },
    goToHowToBuy() {
        const infoSection = document.getElementById('info-section');
        window.scrollTo({
            top: infoSection.offsetTop - 80,
            behavior: "smooth"
        });
    },
    goToWallet() {
        window.open("https://wallet.blockben.com/");
    }
  },
  computed: {
    ...mapGetters({
      getSelectedLanguage: "settings/getSelectedLanguage",
    }),
    needCarousel() {
        return window.innerWidth < 1098;
    }
  },
}
</script>

<style lang="scss" scoped>
.top-img-container {
    position: relative;
    background-color: #0F003F;
    .text-section {
        margin-top: 61px;
        position: absolute;
        width: 100%;
        .title {
            color: #01DBA8;
        }
        .sub {
            font-size: 16px;
            color: #fff;
            font-weight: 400;
        }
    }
    .shadow {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 130px;
    }
    .button-container {
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translate(-50%, -50%);
        button {
            font-family: "HKNova-Bold", sans-serif !important;
            background-color: #01DBA8;
            color: #17025C;
            width: 100%;
            height: 56px;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            text-transform: uppercase;
            &:hover {
                color: #fff;
            }
        }
    }
    @media(max-width: 1000px) {
        padding-top: 70px;
        margin-bottom: -1px;
        .mobile-img {
            padding-top: 160px;
            margin: 0 auto;
        }
        .text-section {
            margin-top: 0;
        }
        .button-container {
            width: 100%;
            padding: 0 10px;
            bottom: 0;
        }
    }
}

.advantages-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: 152px;
    background-color: #0F003F;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(to right, transparent, #95C8F8, transparent) 1;
    padding: 0 166px;
    @media(max-width: 1000px) {
        border-top: 0;
        border-bottom: 1px solid transparent;
        border-image: linear-gradient(to right, transparent, #95C8F8, transparent) 1;
        flex-direction: row-reverse;
        padding: 0 18px;
        .advantage {
            padding: 0 24px;
        }
    }
    .advantage {
        .title {
            color: #D1D1D1;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
        }
        .img {
            margin: 0 auto;
        }
    }
}

.boxes-container {
    min-height: 152px;
    background-color: #0F003F;
    padding: 65px 166px 75px;
    @media (max-width: 1097px) {
        padding: 65px 0 75px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .title {
        color: #01DBA8;
        font-size: 38px;
        font-weight: 700;
        line-height: 52px;
        text-align: center;
    }
    .boxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        position: relative;
        max-width: 1200px;
        margin: 0 auto;
        .holox-box {
            width: 352px;
            margin: 0 12px;
            @media (max-width: 1097px) {
                margin-top: 35px;
                margin: 35px 8px 0;
            }
            @media (max-width: 768px) {
                margin: 0 auto;
                margin-top: 35px;
                width: 80%;
            }
            .img {
                border: 1px solid;
                border-color: rgba(203, 187, 255, 0.2);
                border-radius: 10px;
            }
            .title {
                font-family: "HKNova-Bold", sans-serif !important;
                margin-top: 35px;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
                color: #A3B6EC;
                height: 96px;
            }
            .description {
                margin-top: 24px;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: #D1D1D1;
                height: 240px;
            }
            .price {
                margin-top: 35px;
                font-size: 24px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
                color: rgba(1, 219, 168, 1);
            }
            .button-container {
                margin-top: 47px;
                .button {
                    font-family: "HKNova-Bold", sans-serif !important;
                    width: 100%;
                    height: 54px;
                    background-color: rgba(1, 219, 168, 1);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 22px;
                    color: #17025C;
                    text-transform: uppercase;
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
        &::v-deep {
            .owl-carousel {
                .owl-dots {
                    position: absolute;
                    top: 27px;
                    left: 50%;
                    transform: translateX(-50%);
                    .owl-dot {
                        span {
                            background: rgba(149, 200, 248, 1);
                        }
                        &.active {
                            span {
                                background: rgba(1, 219, 168, 1);
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                }
                .owl-nav {
                    .owl-prev {
                        background: 0 !important;
                        position: absolute;
                        top: 50%;
                        left: 0;
                    }
                    .owl-next {
                        background: 0 !important;
                        position: absolute;
                        top: 50%;
                        right: 0;
                    }
                }
            }
        }
    }
}

.holox-info-container {
    background-color: #0F003F;
    padding: 56px 166px;
    border-top: 1px solid rgba(149, 200, 248, 1);
    @media(max-width: 768px) {
        padding: 40px 0px;
    }
    .title {
        font-size: 38px;
        font-weight: 700;
        line-height: 52px;
        text-align: center;
        color: rgba(1, 219, 168, 1);
        margin-bottom: 48px;
    }
    .infos-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 48px;
        max-width: 1112px;
        margin: 0 auto;
        .info {
            width: 350px;
            margin: 22px 8px;
            padding: 45px 32px;
            background: linear-gradient(-43.39deg, #0F003F 2.69%, #2F1B72 98.97%);
            border-radius: 10px;
            border: 1px solid rgba(203, 187, 255, 0.3);
            @media(max-width: 768px) {
                margin: 8px 0px;
                width: 80%;
            }
            .title-section {
                display: flex;
                align-items: center;
                @media (max-width: 768px) {
                    flex-direction: column;
                    .box-title {
                        margin-left: 0 !important;
                        margin-top: 25px;
                    }
                }
                .img {
                    height: 28px;
                    width: 28px;
                }
                .box-title {
                    margin-left: 24px;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: left;
                    color: rgba(255, 255, 255, 1);
                }
            }
            .description {
                margin-top: 36px;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: rgba(209, 209, 209, 1);
                &::v-deep {
                    .highlight {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        text-align: left;
                    }   
                }
            }
        }
    }
    .footnote {
        font-size: 12px;
        font-weight: 400;
        line-height: 23px;
        text-align: left;
        color: rgba(209, 209, 209, 1);
        max-width: 1112px;
        margin: 0 auto;
        margin-top: 34px;
        @media(max-width: 768px) {
            display: flex;
            justify-content: space-around;
            padding: 0px 15px;
        }
    }
    .button-container {
        display: flex;
        justify-content: space-around;
        margin-top: 54px;
        @media(max-width: 768px) {
            padding: 0 10px;
        }
        button {
            font-family: "HKNova-Bold", sans-serif !important;
            width: 350px;
            height: 54px;
            @media(max-width: 768px) {
                width: 100%;
            }
            background-color: #01DBA8;
            color: #17025C;
            height: 56px;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            text-transform: uppercase;
            &:hover {
                color: #fff;
            }
        }
    }
}

.headline-container {
    padding: 80px 20px;
    @media(max-width: 768px) {
        padding: 40px 20px;
    }
}

.steps-section {
    background-color: rgba(75, 46, 170, 1);
    color: #fff;
}

.steps-container {
    display: flex;
    justify-content: space-around;
    gap: 32px;
}

.stepbox {
    flex: 50%;
    max-width: 540px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    color: rgba(70, 102, 143, 1);
    padding: 30px;
}

.step-title {
    display: flex;
    align-items: center;
    gap: 16px;
    color: rgba(38, 42, 82, 1);
    
    font-size: 18px;
    font-family: "HKNova";
    font-weight: 700;
    line-height: 24px;
}


@media(max-width: 768px) {
    .steps-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .step-title {
        flex-direction: column;
        justify-content: center;
    }
}

.highlight {
    color: rgba(75, 46, 170, 1);
    font-weight: 700;
}

.price {
    font-size: 30px;
    font-family: "HKNova";
    line-height: 52px;
    font-weight: 700;
}

.more-cards-section {
    padding: 49px 20px 61px 15px;
    .title {
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        text-align: center;
        color: rgba(38, 42, 82, 1);
    }
}

.img-container {
    position: relative;
    width: 100%;
    .other-products-title {
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 38px;
        font-weight: 700;
        line-height: 52px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
    }
    img {
        width: 100%;
    }
    .blur {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.firstCard {
    background-color: rgba(246, 244, 255, 1);
}

.product-light .paragraph, .firstCard .paragraph{
    color: rgba(70, 102, 143, 1);
}

.priceInfo {
    color: rgba(70, 102, 143, 1);
}

.paragraph {
    font-family: "HKNova";
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
}

.price-primary {
    color: rgba(75, 46, 170, 1);
}
.price-secondary {
    color: rgba(45, 228, 213, 1);
}

@media (max-width: 768px) {
    .card {
        text-align: center;
        .title {
            margin-top: 32px;
        }
    }
}

.support {
    background-color: rgba(75, 46, 170, 1);
    color: #fff;
    .underline{
        text-decoration: underline;
    }
}

.card-img-container img{
    margin: auto;
}

</style>